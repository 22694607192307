<header class="header">
	<div class="header__links header__links_left">
		@for (link of leftLinks$ | async; track link.url) {
			<a
				class="header__link"
				[routerLink]="link.url"
			>
				{{ link.title }}
			</a>
		}
	</div>
	<img
		class="header__logo"
		[src]="chamberLogoUrl | ygmcAvatarPlaceholder"
		alt="logo"
		[routerLink]="webRouterPaths.landing.url"
	/>
	<div class="header__links header__links_right">
		@for (link of rightLinks$ | async; track link.url) {
			<a
				class="header__link"
				[routerLink]="link.url"
			>
				{{ link.title }}
			</a>
		}
	</div>
</header>
