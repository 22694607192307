import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Observable, combineLatest, filter, map, shareReplay, startWith, switchMap } from 'rxjs';

import { ChamberValidationService } from '@ygm/common/core/services/chamber-validation.service';
import { AvatarPlaceholderPipe } from '@ygm/common/shared/pipes/avatar-placeholder.pipe';

import { ContractService } from '../../../core/services/contract.service';
import { UserService } from '../../../core/services/user.service';
import { injectWebAppRoutes } from '../../../features/shared/web-route-paths';

import { HeaderLink, getHeaderLinks } from './header-links';

/** Header component. */
@Component({
	selector: 'ygmpw-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		RouterModule,
		CommonModule,
		AvatarPlaceholderPipe,
	],
})
export class HeaderComponent {
	private readonly userService = inject(UserService);

	private readonly chamberValidationService = inject(ChamberValidationService);

	private readonly contractService = inject(ContractService);

	/** Web router paths. */
	protected readonly webRouterPaths = injectWebAppRoutes();

	/** Chamber logo url. */
	protected readonly chamberLogoUrl = inject(ChamberValidationService).chamberInformation.branding.chamberLogo;

	/** Links on left side. */
	protected readonly leftLinks$: Observable<HeaderLink[]>;

	/** Links on right side. */
	protected readonly rightLinks$: Observable<HeaderLink[]>;

	public constructor() {
		const hasRenewalContracts$ = this.userService.isAuthorized$.pipe(
			filter(Boolean),
			switchMap(() => this.contractService.hasRenewalContracts()),
			startWith(false),
			shareReplay({ bufferSize: 1, refCount: true }),
		);
		const headerLinks$ = combineLatest([
			this.userService.isAuthorized$,
			this.chamberValidationService.chamberInformation$,
			hasRenewalContracts$,
			this.userService.currentUser$,
		]).pipe(
			map(([isAuthorized, chamberInfo, hasRenewalContracts, user]) =>
				getHeaderLinks(isAuthorized, chamberInfo.campaign?.status, hasRenewalContracts, user?.isCloaked ?? false)),
			shareReplay({ bufferSize: 1, refCount: true }),
		);
		this.leftLinks$ = headerLinks$.pipe(
			map(links => links.filter(link => link.side === 'left')),
		);
		this.rightLinks$ = headerLinks$.pipe(
			map(links => links.filter(link => link.side === 'right')),
		);
	}
}
