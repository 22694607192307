@if (chamberValidationService.chamberInformation$ | async; as chamberInfo) {
	<footer class="footer">
		<div class="footer__content">
			<a routerLink="/">
				<img
					[src]="chamberInfo.branding.chamberLogo | ygmcAvatarPlaceholder"
					alt="logo"
					class="footer__logo"
				/>
			</a>
			<div class="footer__section">
				<span class="footer__text typography-body-small">GET IN TOUCH</span>
				<span class="typography-button footer__address">
					{{ chamberInfo.physicalAddress }}, {{ chamberInfo.physicalAddressCity }}
				</span>
				<span class="typography-button">
					{{ chamberInfo.physicalAddressState }}
				</span>
				<span class="typography-button">
					{{ chamberInfo.physicalAddressZip }}
				</span>
				<span class="footer__text typography-body-small">{{ chamberInfo.phoneNumber }}</span>
			</div>
			<div class="footer__section">
				<span class="footer__text typography-body-small">CONNECT</span>
				<div class="footer__link typography-button">
					@if (chamberInfo.ceoEmail !== "") {
						<a
							target="_blank"
							[href]="chamberInfo.ceoEmail | ygmcMailLink"
						>
							<mat-icon class="footer__icon">mail</mat-icon>
						</a>
					}
					@if (chamberInfo.twitterUrl !== "") {
						<a
							target="_blank"
							[href]="chamberInfo.twitterUrl"
						>
							<mat-icon
								svgIcon="twitter"
								class="footer__icon"
							/>
						</a>
					}
					@if (chamberInfo.linkedinUrl !== "") {
						<a
							target="_blank"
							[href]="chamberInfo.linkedinUrl"
						>
							<mat-icon
								svgIcon="linkedin"
								class="footer__icon"
							/>
						</a>
					}
					@if (chamberInfo.facebookUrl !== "") {
						<a
							target="_blank"
							[href]="chamberInfo.facebookUrl"
						>
							<mat-icon class="footer__icon">facebook</mat-icon>
						</a>
					}
					@if (chamberInfo.youtubeUrl !== "") {
						<a
							target="_blank"
							[href]="chamberInfo.youtubeUrl"
						>
							<mat-icon
								svgIcon="youtube"
								class="footer__icon"
							/>
						</a>
					}
					@if (chamberInfo.instagramUrl !== "") {
						<a
							target="_blank"
							[href]="chamberInfo.instagramUrl"
						>
							<mat-icon
								svgIcon="instagram"
								class="footer__icon"
							/>
						</a>
					}
				</div>
			</div>
			<div class="footer__section">
				<span class="footer__text typography-body-small">POWERED BY</span>
				<img
					src="/assets/logo.svg"
					alt="logo"
					class="footer__logo"
				/>
			</div>
		</div>
		<span class="footer__message typography-body-small">
			© 2023 All Rights Reserved. Privacy Policy | Terms of Use
		</span>
	</footer>
}
