import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { ChamberValidationService } from '@ygm/common/core/services/chamber-validation.service';
import { AvatarPlaceholderPipe } from '@ygm/common/shared/pipes/avatar-placeholder.pipe';
import { MailLinkPipe } from '@ygm/common/shared/pipes/mail-link.pipe';
import { NullablePipe } from '@ygm/common/shared/pipes/nullable.pipe';

/** Footer component. */
@Component({
	selector: 'ygmpw-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		RouterModule,
		CommonModule,
		MatIconModule,
		MailLinkPipe,
		NullablePipe,
		AvatarPlaceholderPipe,
	],
})
export class FooterComponent {
	/** Chamber validation service. */
	protected readonly chamberValidationService = inject(ChamberValidationService);
}
