import { Pipe, PipeTransform } from '@angular/core';

type ValueType = string;

/** Prefix `mailto:` to the mail address to use for the href of a tag. */
@Pipe({
	name: 'ygmcMailLink',
	standalone: true,
})
export class MailLinkPipe implements PipeTransform {

	/** @inheritdoc */
	public transform(value: ValueType): string {
		return `mailto:${value}`;
	}
}
