import { CampaignStatus } from '@ygm/common/core/models/campaign';

import { webRoutePaths } from '../../../features/shared/web-route-paths';

/** Header link data. */
export type HeaderLink = {

	/** Url of the header link. */
	readonly url: string;

	/** Title. */
	readonly title: string;

	/** The statuses in which this title link can be displayed.. */
	readonly availableStatuses: readonly CampaignStatus[];

	/** Whether link is on left or right side. */
	readonly side: 'right' | 'left';

	/** Link is available if user is authorized or not. Null means both. */
	readonly isAuthorized: boolean | null;
};

const HEADER_LINKS: readonly HeaderLink[] = [
	{
		title: webRoutePaths.renewalContracts.title,
		url: webRoutePaths.renewalContracts.url,
		availableStatuses: [CampaignStatus.Renewal],
		isAuthorized: true,
		side: 'left',
	},
	{
		title: webRoutePaths.dashboard.title,
		url: webRoutePaths.dashboard.url,
		availableStatuses: [CampaignStatus.Live, CampaignStatus.Closed],
		isAuthorized: true,
		side: 'left',
	},
	{
		title: 'Products',
		url: webRoutePaths.marketingProducts.url,
		availableStatuses: [CampaignStatus.Live, CampaignStatus.Closed],
		isAuthorized: true,
		side: 'left',
	},
	{
		title: 'Remaining',
		url: webRoutePaths.remainingSponsorships.url,
		availableStatuses: [CampaignStatus.Live, CampaignStatus.Closed],
		isAuthorized: true,
		side: 'left',
	},
	{
		title: webRoutePaths.marketingProducts.title,
		url: webRoutePaths.marketingProducts.url,
		availableStatuses: [CampaignStatus.Live],
		isAuthorized: false,
		side: 'left',
	},
	{
		title: webRoutePaths.purchasingMembers.title,
		url: webRoutePaths.purchasingMembers.url,
		availableStatuses: [CampaignStatus.Live],
		isAuthorized: null,
		side: 'left',
	},
	{
		title: webRoutePaths.contracts.title,
		url: webRoutePaths.contracts.url,
		availableStatuses: [CampaignStatus.Live, CampaignStatus.Closed],
		isAuthorized: true,
		side: 'right',
	},
	{
		title: webRoutePaths.leaderboard.title,
		url: webRoutePaths.leaderboard.url,
		availableStatuses: [CampaignStatus.Live, CampaignStatus.Closed],
		isAuthorized: true,
		side: 'right',
	},
	{
		title: 'My Account',
		url: webRoutePaths.profile.url,
		availableStatuses: [CampaignStatus.Live, CampaignStatus.Closed, CampaignStatus.Renewal],
		isAuthorized: true,
		side: 'right',
	},
	{
		title: 'My Account',
		url: webRoutePaths.profile.children.edit.url,
		availableStatuses: [CampaignStatus.Created, CampaignStatus.Done],
		isAuthorized: true,
		side: 'right',
	},
	{
		title: webRoutePaths.auth.children.login.title,
		url: webRoutePaths.auth.children.login.url,
		availableStatuses: [
			CampaignStatus.Created,
			CampaignStatus.Renewal,
			CampaignStatus.Live,
			CampaignStatus.Closed,
			CampaignStatus.Done,
		],
		isAuthorized: false,
		side: 'right',
	},
];

/**
 * Get available header links.
 * @param isAuthorized If user is authorized or not.
 * @param campaignStatus Status of current campaign.
 * @param hasRenewalContracts If campaign has renewal contracts or not.
 * @param isUserCloaked Is user cloaked.
 */
export const getHeaderLinks = (
	isAuthorized: boolean,
	campaignStatus: CampaignStatus | undefined,
	hasRenewalContracts: boolean,
	isUserCloaked: boolean,
): HeaderLink[] =>
	HEADER_LINKS.filter(link => {

		// Checks links for authorized.
		if (link.isAuthorized != null && link.isAuthorized !== isAuthorized) {
			return false;
		}

		// For a not-cloaked user in a closed campaign should imitate as an open one.
		if (CampaignStatus.Closed === campaignStatus && !isUserCloaked) {
			return link.availableStatuses.includes(CampaignStatus.Created);
		}

		// Checks available links depending on the campaign.
		if (campaignStatus == null || !link.availableStatuses.includes(campaignStatus)) {
			return false;
		}

		// Don't show `Renewal Contracts` if the campaign doesn't have renewal contracts.
		if (!hasRenewalContracts && link.url === webRoutePaths.renewalContracts.url) {
			return false;
		}

		return true;

	});
